.modalHeader {
    padding: var(--spacing-core-6);
    flex-shrink: 0;
    border-bottom: 1px solid var(--color-background-tertiary-default);
}

.modalContent  {
    flex-shrink: 1;
    padding: 0 var(--spacing-core-6) var(--spacing-core-6) var(--spacing-core-6);
    scrollbar-width: thin;
    overflow-y: scroll;
}

.modalFooter {
    flex-shrink: 0;
    box-shadow:
        0 -8px 16px rgb(0 0 0 / 6%),
        0 -4px 4px rgb(0 0 0 / 4%),
        0 -1px 2px rgb(0 0 0 / 3%);
}

.errorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-core-4) var(--spacing-core-6);
    gap: var(--spacing-core-4);
    background: #f3f6f8;
}

.errorIcon {
    color: var(--color-action-destructive-default);
}

.submitWrapper {
    padding: var(--spacing-core-6);
}

.submitButton {
    width: 100%;
}
