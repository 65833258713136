@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

dialog[open].modal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;
    overflow: hidden;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-width: 496px;
        max-height: 80%;
        border-radius: var(--border-radius-core-l);
    }
}
